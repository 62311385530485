//BRAND
export const name = 'Cursalo';
export const company = 'Cursalo';
export const websiteURL = 'https://cursalo.com';
export const serverURL = 'https://cursalo.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/cursalo-47d68.appspot.com/o/IMG_8676.PNG?alt=media&token=0a8bab81-5421-4967-a8a9-e5cf63ba192f';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = true;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Gerardo";
export const profession = 'Cursalo';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/cursalo-47d68.appspot.com/o/IMG_8676.PNG?alt=media&token=0a8bab81-5421-4967-a8a9-e5cf63ba192f';

//PAYPAL
export const paypalPlanIdOne = "P-9J344944PS519920FM36BTJA";
export const paypalPlanIdTwo = "P-3DD00730G6668134CM36BSOY";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "prod_Qx2fgle8UmVEL9";
export const stripePlanIdTwo = "prod_Qx2gFCT90ZErjY";

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8";
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis";
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "150644331076-flt197jvuqg9ohlf6q9rsjk3e3qomjd2.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";